<template>
  <v-card class="offers rounded-10 overflow-hidden" flat outlined>
    <v-card-title class="pt-0 px-0">
      <v-tabs class="tabs" grow>
        <v-tab
          class="text-none font-weight-bold fs-17"
          style="color: #aeaeae"
          v-for="(tab, index) in tabs"
          :key="index"
          @click="changeTab(tab)"
          :class="{ 'offers-active-class': isActiveTab(tab) }"
        >
          <v-icon :color="isActiveTab(tab) ? 'primary' : ''" size="25" left>
            {{ tab.icon }}
          </v-icon>
          {{ tab.name }}
          <v-chip
            color="red"
            small
            class="ml-2 light--text"
            v-if="tab.name === 'Cart' && cart_counts > 0"
          >
            {{ cart_counts }}
          </v-chip>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="px-1">
      <router-view :key="$router.fullPath"></router-view>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import request from '@/services/axios_instance'

export default {
  name: 'OffersOrders',
  data() {
    return {
      active_tab: 0,
      type: 'products',
      id: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('cart', ['orderHandlers', 'cart_counts']),
    tabs() {
      let tabs = [
        {
          id: 1,
          name: 'Offers',
          route_name: 'offers-home',
          icon: 'mdi-shopping-search'
        },
        {
          id: 2,
          name: 'Board',
          route_name: 'offers-dashboard',
          icon: 'mdi-view-dashboard-outline'
        },
        {
          id: 3,
          name: 'Cart',
          route_name: 'offers-checkout',
          icon: 'mdi-cart'
        }
      ]
      return tabs
    },
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        {
          text: 'Offers',
          disabled: true,
          route: { name: `offers-home` }
        }
      ]
    },
    allowed_members() {
      return this.orderHandlers.map((i) => {
        return i.id
      })
    },
    can_manage_incoming_order() {
      return this.user.is_admin || this.allowed_members.includes(this.user.id)
    }
  },
  created() {
    this.getOrderHandlers(this.user.company.id)
    this.getCategories()
    this.getCartCounts()
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
    if (this.$route.params.type) {
      this.type = this.$route.params.type.toLowerCase()
      let index = this.tabs.findIndex(
        (item) => item.type.toLowerCase() === this.type
      )
      this.active_tab = ~index ? index : 0
    }
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    }
  },
  methods: {
    ...mapActions('cart', [
      'getCategories',
      'getOrderHandlers',
      'getCartCounts'
    ]),
    changeTab(tab) {
      this.type = tab.type
      this.$router.push({ name: tab.route_name }).catch(() => {})
    },
    isActiveTab(tab) {
      return this.$route.name === tab.route_name
    }
  }
}
</script>
<style lang="scss">
.offers {
  .tabs {
    .v-tabs-slider-wrapper {
      display: none !important;
    }
  }
}
</style>
<style scoped lang="scss">
.offers-active-class {
  color: #223a6b !important;
}
</style>
